import React from "react";
import Abnormal from "../components/cats/Abnormal";

export default function CategoryPosts() {
  return (
    <div className="page-container">
      <h1>அமானுடம்</h1>
      <Abnormal />
    </div>
  );
}
