import React from "react";
import Mystery from "../components/cats/Mystery";

export default function CategoryPosts() {
  return (
    <div className="page-container">
      <h1>வினோதங்கள்</h1>
      <Mystery />
    </div>
  );
}
