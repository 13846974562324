import React from "react";
import CatList from "../components/CatList";

export default function CategoryPosts() {
  return (
    <div className="page-container">
      <h1>வளாகம்</h1>
      <CatList />
    </div>
  );
}
