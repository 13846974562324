import React from "react";
import History from "../components/cats/History";

export default function CategoryPosts() {
  return (
    <div className="page-container">
      <h1>வரலாறுகள்</h1>
      <History />
    </div>
  );
}
