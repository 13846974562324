import React from "react";
import Scholars from "../components/cats/Scholars";

export default function CategoryPosts() {
  return (
    <div className="page-container">
      <h1>மேதைகள்</h1>
      <Scholars />
    </div>
  );
}
