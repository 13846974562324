import React from "react";
import Puzzles from "../components/cats/Puzzles";

export default function CategoryPosts() {
  return (
    <div className="page-container">
      <h1>புதிர்கள்</h1>
      <Puzzles />
    </div>
  );
}
