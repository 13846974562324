import React from "react";
import Brief from "../components/cats/Brief";

export default function CategoryPosts() {
  return (
    <div className="page-container">
      <h1>செய்தி சுருக்கம்</h1>
      <Brief />
    </div>
  );
}
