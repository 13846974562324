import React from "react";
import Others from "../components/cats/Others";

export default function CategoryPosts() {
  return (
    <div className="page-container">
      <h1>ஏனையவை</h1>
      <Others />
    </div>
  );
}
