import React from "react";
import Science from "../components/cats/Science";

export default function CategoryPosts() {
  return (
    <div className="page-container">
      <h1>அறிவியல்</h1>
      <Science />
    </div>
  );
}
